<template>
  <div class="printPaperList_box">
    <div class="printPaperList_content">
      <div id="printPaperList" class="p30">
        <Row type="flex" :gutter="gutterItem" class="mb20">
          <Col :span="12" class="mb10" v-for="(ele, index) in infoList" :key="index">
            <span class="basic_title">{{ ele.name }}</span>
            <span class="basic_txt">{{ details[ele.paramsKey] }}</span>
          </Col>
        </Row>
        <div class="printPaperList_table_box">
          <table width="100%">
            <thead class="thead_bg">
            <tr>
              <td v-for="(talg,index) in theadList" :key="index" align="center" v-show="filterColumns.includes(talg.key)">{{ talg.title }}</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(val,index) in tableData" :key="index">
              <td align="center" v-show="filterColumns.includes('goodsUrl')">
                <img class="img_styles" :src="setImgPath(val.goodsUrl)" crossOrigin="anonymous" @error="setErrorImg($event)" alt=""/>
              </td>
              <td align="center" v-show="filterColumns.includes('spu')">{{ val.spu }}</td>
              <td align="center" v-show="filterColumns.includes('goodsSku')">{{ val.goodsSku }}</td>
              <td align="center" v-show="filterColumns.includes('ymsSpu')">{{ val.ymsSpu }}</td>
              <td align="center" v-show="filterColumns.includes('ymsSku')">{{ val.ymsSku }}</td>
              <td align="center" v-show="filterColumns.includes('expectedNumber')">{{ val.expectedNumber }}</td>
              <td align="center" v-show="filterColumns.includes('goodsCnDesc')">{{ val.goodsCnDesc }}</td>
              <td align="center" v-show="filterColumns.includes('goodsAttributes')">{{ val.goodsAttributes }}</td>
            </tr>
            </tbody>
          </table>
          <div class="signature_box">
            <span class="txt">{{ $t('key1002552') }} {{ $t('key1002553') }}</span>
            <span class="txt">{{ $t('key1002554') }} {{ $t('key1002555') }}</span>
          </div>
        </div>
      </div>
      <div class="print_none">
        <Button type="primary" class="mb10" @click="printBtn">{{ $t('key1000908') }}</Button>
        <Button type="primary" class="mb10" @click="downloadBtn">{{ $t('key1000461') }}</Button>
        <Button type="primary" class="mb10" @click="cancelPrintBtn">{{ $t('key1002556') }}</Button>
        <div style="height: 35px;position: relative">
          <local-filterColumns
            :columns="theadList"
            :filterName="'printPickList'"
            @setTableColumns="setTableColumns">
          </local-filterColumns>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import pdf from '@/utils/pdf';
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "printPaperList",
  mixins: [Mixin],
  data() {
    return {
      infoList: [
        {name: alias47916751af154eb5b47cd5de9d34633d.t('key1000185'), paramsKey: 'pickingNumber'},
        {name: alias47916751af154eb5b47cd5de9d34633d.t('key1000920'), paramsKey: 'createdTime'},
        {name: alias47916751af154eb5b47cd5de9d34633d.t('key1000187'), paramsKey: 'goodsQuantityNumber'},
        {name: alias47916751af154eb5b47cd5de9d34633d.t('key1000189'), paramsKey: 'skuClassNumber'}
      ],
      theadList: [
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1000161'), key: 'goodsUrl'},
        {title: 'SPU', key: 'spu'},
        {title: 'SKU', key: 'goodsSku'},
        {title: `${setConfigCharacters}SPU`, key: 'ymsSpu'},
        {title: `${setConfigCharacters}SKU`, key: 'ymsSku'},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1000192'), key: 'expectedNumber'},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1000193'), key: 'goodsCnDesc'},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1000194'), key: 'goodsAttributes'}
      ], // 表头数据
      tableData: [],
      filterColumns: [],
      details: {}
    }
  },
  created() {
    let pickingGoodsNos = this.$route.query.data;
    let type = this.$route.query.type;
    this.getList(pickingGoodsNos, type);
  },
  methods: {
    // 获取自定义列数据
    setTableColumns(data) {
      this.filterColumns = data.map(i => i.key);
    },
    // 获取列表数据
    getList(pickingGoodsNos, type) {
      let v = this;
      let query = {
        packageIds: pickingGoodsNos.split(','),
        printType: 1
      };
      v.tableData = [];
      let url = '';
      if (type === 'supplierOrderList') {
        url = api.post_packageInfo_printPrepareGoods;
      } else if (type === 'stockList') {
        url = api.post_packageInfo_printPrepareGoodsStock;
      }
      v.axios.post(url, query, {loading: true}).then(res => {
        if (res.data.code === 0) {
          let data = res.data.datas;
          if (data) {
            v.details = data;
            v.tableData = data.queryByGoodsDetailResultPage ? data.queryByGoodsDetailResultPage.list : [];
            if (v.tableData.length > 0) {
              v.tableData.map((item) => {
                item.createdTime = v.$uDate.dealTime(item.createdTime, 'fulltime');
              })
            }
          }
        }
      });
    },
    // 打印
    printBtn() {
      window.print();
    },
    // 下载
    downloadBtn() {
      pdf('#printPaperList', 'web');
    },
    // 取消打印
    cancelPrintBtn() {
      this.$router.push('/supplierOrderList');
    },
  }
}
</script>
<style>
.iview-table-setWidth .ivu-table:before {
    height: 0;
}

.iview-table-setWidth .ivu-table:after {
    height: 0;
}

.iview-table-setWidth .ivu-table-overflowX {
    overflow: hidden;
}

@page {
    margin: 0;
    padding: 0;
}

@media print {
    html,
    body,
    #app {
        min-width: auto;
    }

    * {
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    .iview-table-setWidth .ivu-table-header table {
        width: 100% !important;
        table-layout: initial;
    }

    .iview-table-setWidth .ivu-table-body table {
        width: 100% !important;
        table-layout: initial;
    }

    .iview-table-setWidth .ivu-table:before {
        height: 0;
    }

    .iview-table-setWidth .ivu-table:after {
        height: 0;
    }


    .printPaperList_content {
        width: 100%;
        background-color: #fff;
        margin: 0 auto;
        position: relative;
    }

    .printPaperList_box {
        width: 100%;
        font-size: 16px;
        background-color: #ccc;
        padding: 0;
    }

    .printPaperList_box .print_none {
        display: none !important;
    }
}

* {
    margin: 0;
    padding: 0;
}
</style>
<style lang="less" scoped>
.printPaperList_box {
  background-color: rgb(204, 204, 204);
  font-size: 16px;
  padding: 34px 56px;

  table, table td {
    border: 1px solid #9a9a9a;
    border-collapse: collapse;
  }

  table {
    table-layout: fixed;
    word-wrap: break-word;
    word-break: break-all;

    td {
      word-break: break-all;
    }
  }

  .printPaperList_content {
    width: 1000px;
    background-color: #fff;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }

  .thead_bg {
    background-color: #ccc;

    tr {
      border-top: 1px solid #9a9a9a;

      td {
        padding: 8px 0;
        font-weight: bold;
      }
    }
  }

  .printPaperList_table_box {
    .img_styles {
      width: 80px;
      height: auto;
      max-height: 80px;
      display: inline-block;
      margin: 8px;
    }

    .signature_box {
      margin-top: 50px;
      display: flex;
      justify-content: flex-end;

      .txt {
        margin-right: 100px;
        font-size: 14px
      }
    }
  }

  .print_none {
    position: absolute;
    right: -160px;
    top: 0;
    display: flex;
    flex-direction: column;
  }
}
</style>